export const CHANNELS = {
  CUSTOM: "custom",
  EMAIL: "email",
  SMS: "sms",
};
export const DEFAULT_IMAGE_FOLDER = "connectpath";
export const LATEST_STACK_VERSION = 1;
export const MEDIA_STREAM_TYPES = {
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
};
export const PARTNERS = {
  DIGITAL_ISLAND: "digitalisland",
  CDW: "cdw",
};
export const WEBHOOKS_PROVIDERS = {
  TWILIO: "twilio",
  OFFICE365: "office365",
  GOOGLE_WORKSPACE: "googleWorkSpace",
};
export const USERS_INTEGRATIONS_LIMIT = 5;
export const USERS_DEFAULT_LIMIT = 15;
export const IDLE = {
  MINIMUM_MINUTES: 1,
  MAXIMUM_MINUTES: 30,
};
export const AGENT_STATUSES = {
  NOT_ROUTABLE: "not_routable",
  AVAILABLE: "Available",
  OFFLINE: "Offline",
  BUSY: "Busy",
  PENDING_BUSY: "PendingBusy",
  CALLING_CUSTOMER: "CallingCustomer",
  AFTER_CONTACT_WORK: "AfterContactWork",
};
export const IDLE_UPDATE_RESTRICTED_STATUSES = [
  AGENT_STATUSES.PENDING_BUSY,
  AGENT_STATUSES.BUSY,
  AGENT_STATUSES.CALLING_CUSTOMER,
];
export const SECURITY_PROFILES = {
  DEXTR_ADMIN: "Dextr Admin",
  DEXTR_AGENT: "Dextr Agent",
  INSTANCE_ADMIN: "Instance Admin",
};

export const VIDEO_CALL_CHIME_TRANSCRIPTION_CONFIGURATION_SPECIALTY = [
  "PRIMARYCARE",
  "CARDIOLOGY",
  "NEUROLOGY",
  "ONCOLOGY",
  "RADIOLOGY",
  "UROLOGY",
];

export const VIDEO_CALL_CHIME_TRANSCRIPTION_CONFIGURATION_TYPE = ["CONVERSATION", "DICTATION"];

export const ID_PROVIDER_DEFAULT_ALPHABET = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-";
export const ID_PROVIDER_DEFAULT_SIZE = 10;

export const CHIME_VIDEO_CALL_ID = "1fe97c08-d696-4d0e-b1c1-2eaf0883b75c";

export const PRESIGNED_S3_METHODS = {
  PUT: "PUT",
  GET: "GET",
  ALL: "ALL",
};

export const LIST_INTEGRATIONS = {
  GOOGLE_AUTH: "GOOGLE_AUTH",
};

export const INTEGRATION_PROPERTIES = {
  GOOGLE_AUTH: {
    TYPE: "googleAuth",
    PROVIDER: "Google",
    TYPENAME: "GoogleAuthIntegrationType",
  },
};
export const STEP_MACHINE_EVENT_NAMES = {
  SUCCESS: "CUSTOM_STACK_DEPLOYED",
  FAILURE: "CUSTOM_STACK_DEPLOY_FAILED",
};

export const QUICKSIGHT_DEFINITION_ID = "47d6588c-23c5-48cc-ab52-7fc4f038cac5";

export const TASK_TEMPLATE = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const TASK_TEMPLATE_FIELDS = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  DATE: "DATE",
  BOOLEAN: "BOOLEAN",
  TEXT: "TEXT",
  DESCRIPTION: "DESCRIPTION",
  NAME: "NAME",
  URL: "URL",
  FALSE: "false",
  TRUE: "true",
};

export const S3_URL_EXPIRATION = 39600;
export const DIRECTION_IN = "IN";
export const INITIATION_METHODS = {
  ABANDONED_AGENT: "ABANDONED_AGENT",
  REJECTED: "REJECTED",
  MISSED: "MISSED",
  FAILED: "FAILED",
};

export const DATA_TYPES = {
  STRING: "string",
};
export const REGISTERED_USER_EMBED_URLS = "RegisteredUser";
export const ANONYMOUS_USER_EMBED_URLS = "AnonymousUser";

export const API_METHODS = {
  GET: "Get",
  DELETE: "Delete",
  LIST: "List",
};
